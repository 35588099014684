import { Store } from 'vuex'

import { Page } from '@/store/interfaces'
import { AutoSaveMutations } from '@/store/modules/autoSave'
import { rootStore, RootStore, StoreMutations } from '@/store/rootStore'

import { AutoSave } from './AutoSave'
import {
	AddLogoCommand,
	AddPageCommand,
	AddProductsCommand,
	AutoSaveOptions,
	DeletePageCommand,
	DuplicatePageCommand,
	RemoveLogoCommand,
	RemoveProductCommand,
	UpdatePagesIndexesCommand,
	SocketMessage,
	UpdatePageCommand,
	MultiEventsCommand,
	UpdatePageMediaCommand,
	AutoLogoCommand,
} from './types'

export function createAutoSavePlugin(options: AutoSaveOptions) {
	const socket = new AutoSave(options)

	return (store: Store<RootStore>) => {
		socket.getSocket().addEventListener('message', (event: { data: string }) => {
			const message = JSON.parse(event.data) as SocketMessage
			if (socket.isSocketSuccess(message)) {
				const mutation: AutoSaveMutations = { type: 'autoSave/increaseChanges' }
				store.commit(mutation)
			}
		})

		window.onbeforeunload = (evt: BeforeUnloadEvent) => {
			const dialogText = 'We are saving your work. Are you sure you want to leave?'

			if (
				!rootStore.state.autoSave.isSending &&
				!rootStore.state.autoSave.queue.length
			)
				return

			evt.returnValue = dialogText
			return dialogText
		}

		store.subscribe((rawMutation) => {
			const mutation = {
				type: rawMutation.type,
				payload: rawMutation.payload?.payload,
			} as StoreMutations

			switch (mutation.type) {
				case 'project/setProject':
					if (mutation.payload.project_id) {
						socket.setCanEnqueueMessages(true)
						socket.setCanSendMessages(true)
					}
					break
				case 'autoSave/sendMultiCommands': {
					mutation.payload.forEach((command) => {
						socket.sendCommand({
							...command,
						})
					})
					break
				}
				case 'page/addPage':
					socket.enqueue<AddPageCommand>({
						action: 'add_page',
						data: {
							project_id: store.state.project.data.project_id,
							layout_id: mutation.payload.layout_id,
							page_id: mutation.payload.page_id,
							index: mutation.payload.index,
							settings: mutation.payload.settings ?? {},
							cover_image: mutation.payload.cover_image,
							title: mutation.payload.title,
						},
					})
					break
				case 'page/deletePage': {
					socket.enqueue<DeletePageCommand>({
						action: 'delete_page',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.page_id,
							reindex: false,
						},
					})
					socket.enqueue<UpdatePagesIndexesCommand>({
						action: 'update_pages_indexes',
						data: {
							project_id: store.state.project.data.project_id,
							pages: Object.values(store.state.page.basicPages)
								.filter((page) => page.index >= mutation.payload.index)
								.reduce((obj, page) => ({ ...obj, [page.page_id]: page.index }), {}),
						},
					})
					break
				}
				case 'page/deleteMultiplePages': {
					const lowestPageAffected = Object.values(mutation.payload).reduce(
						(prev, curr) => (prev.index < curr.index ? prev : curr)
					)
					socket.enqueue<DeletePageCommand>({
						action: 'delete_page',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: Object.values(mutation.payload)
								.sort((a, b) => b.index - a.index)
								.map((page) => page.page_id)
								.join(','),
							reindex: false,
						},
					})
					socket.enqueue<UpdatePagesIndexesCommand>({
						action: 'update_pages_indexes',
						data: {
							project_id: store.state.project.data.project_id,
							pages: Object.values(store.state.page.basicPages)
								.filter((page) => page.index >= lowestPageAffected.index)
								.reduce((obj, page) => ({ ...obj, [page.page_id]: page.index }), {}),
						},
					})
					break
				}
				case 'page/duplicatePage': {
					const createClonePageCommand = (
						sourcePage: Page,
						targetPage: Page
					): DuplicatePageCommand => {
						return {
							...socket.getBaseCommand(),
							action: 'clone_page',
							data: {
								project_id: store.state.project.data.project_id,
								source_page_id: sourcePage.page_id,
								target_page_id: targetPage.page_id,
							},
						}
					}
					const clonePageCommands = mutation.payload.sourcePages.map(
						(sourcePage, index) => {
							return createClonePageCommand(sourcePage, mutation.payload.pages[index])
						}
					)

					socket.enqueue<MultiEventsCommand>({
						action: 'multi_events',
						commands: clonePageCommands,
					})
					socket.enqueue<UpdatePagesIndexesCommand>({
						action: 'update_pages_indexes',
						data: {
							project_id: store.state.project.data.project_id,
							pages: Object.values(store.state.page.basicPages).reduce(
								(obj, page) => ({ ...obj, [page.page_id]: page.index }),
								{}
							),
						},
					})
					break
				}

				case 'page/addProduct':
					socket.enqueue<AddProductsCommand>({
						action: 'add_products',
						data: {
							products: [mutation.payload.product],
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.page.pageId,
						},
					})
					break
				case 'page/removeProduct':
					socket.enqueue<RemoveProductCommand>({
						action: 'remove_product',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.pageId,
							slot: mutation.payload.slotId,
						},
					})
					break

				case 'page/addLogo':
					socket.enqueue<AddLogoCommand>({
						action: 'add_logo',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.logo.page_id,
							slot: mutation.payload.logo.slot,
							logo_id: mutation.payload.logo.logo_id,
							url: mutation.payload.logo.url,
						},
					})
					break

				case 'page/replaceAllPagesLogo':
					socket.enqueue<AutoLogoCommand>({
						action: 'auto_logo',
						data: {
							project_id: store.state.project.data.project_id,
							slot: mutation.payload.logo.slot,
							logo_id: mutation.payload.logo.logo_id,
							url: mutation.payload.logo.url,
						},
					})
					break

				case 'page/removeLogo':
					socket.enqueue<RemoveLogoCommand>({
						action: 'remove_logo',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.pageId,
							slot: mutation.payload.slotId,
						},
					})
					break

				case 'page/updatePageMedia':
					socket.enqueue<UpdatePageMediaCommand>({
						action: 'update_page_media',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: mutation.payload.pageId,
						},
					})
					break

				case 'page/changeLayout':
				case 'page/setPageSettings':
				case 'page/addCoverImage':
				case 'page/removeCoverImage':
				case 'page/setTitle': {
					if (
						'options' in mutation.payload &&
						mutation.payload.options?.ignoreWebsocket
					) {
						return
					}
					const pageNumber = mutation.payload.page.index
					const page = store.state.page.pages[pageNumber]

					socket.enqueue<UpdatePageCommand>({
						action: 'update_page',
						data: {
							project_id: store.state.project.data.project_id,
							page_id: page.page_id,
							layout_id: page.layout_id,
							index: page.index,
							settings: page.settings,
							cover_image: page.cover_image || '',
							title: page.title || '',
						},
					})

					break
				}

				case 'page/updatePagesIndexes': {
					const pagesMutated = mutation.payload.filter(
						(page) => page.oldIndex !== page.index
					)
					socket.enqueue<UpdatePagesIndexesCommand>({
						action: 'update_pages_indexes',
						data: {
							project_id: store.state.project.data.project_id,
							pages: pagesMutated.reduce(
								(obj, page) => ({ ...obj, [page.page_id]: page.index }),
								{}
							),
						},
					})
					break
				}

				case 'page/reorderSpreadPages': {
					const { leftPage, rightPage } = mutation.payload

					const createUpdatePageCommand = (page: Page): UpdatePageCommand => {
						return {
							...socket.getBaseCommand(),
							action: 'update_page',
							data: {
								project_id: store.state.project.data.project_id,
								page_id: page.page_id,
								layout_id: page.layout_id,
								index: page.index,
								settings: page.settings,
								cover_image: page.cover_image || '',
								title: page.title || '',
							},
						}
					}

					socket.enqueue<MultiEventsCommand>({
						action: 'multi_events',
						commands: [
							createUpdatePageCommand(leftPage),
							createUpdatePageCommand(rightPage),
						],
					})

					break
				}

				case 'autoSave/reconnect':
					{
						socket.setCanSendMessages(true)
						socket.makeNewConnection()
					}

					break
				default:
			}
		})
	}
}
