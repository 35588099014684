import type { Page as NormalizedPage } from '@zoomcatalog/design-templates'
import type { Module } from 'vuex'
import type { EventPayload } from '@/ts-definitions'
import type { BasicPage, Layout, Logo, Page, Product } from '../interfaces'
import type { RootStore } from '../rootStore'

export type MutationExtraOptions = {
	ignoreWebsocket?: boolean
}
export type PageStore = {
	pages: Record<string, Page>
	basicPages: Record<string, BasicPage>
}

type PageNamespace = 'page'

type SetPagesMutation = EventPayload<`${PageNamespace}/setPages`, Page[]>
type SetBasicPagesMutation = EventPayload<
	`${PageNamespace}/setBasicPages`,
	BasicPage[]
>
type AddPagesMutation = EventPayload<`${PageNamespace}/addPages`, Page[]>
type UpdatePagesIndexesMutation = EventPayload<
	`${PageNamespace}/updatePagesIndexes`,
	Page[]
>
type AddPageMutation = EventPayload<`${PageNamespace}/addPage`, Page>
type DuplicatePageMutation = EventPayload<
	`${PageNamespace}/duplicatePage`,
	{ pages: Page[]; sourcePages: Page[] }
>
type DeletePageMutation = EventPayload<`${PageNamespace}/deletePage`, Page>
type DeleteMultiplePagesMutation = EventPayload<
	`${PageNamespace}/deleteMultiplePages`,
	Page[]
>
type ReorderSpreadPagesMutation = EventPayload<
	`${PageNamespace}/reorderSpreadPages`,
	{ leftPage: Page; rightPage: Page }
>

export type PageMutations =
	| SetPagesMutation
	| SetBasicPagesMutation
	| AddPagesMutation
	| UpdatePagesIndexesMutation
	| AddPageMutation
	| DeletePageMutation
	| DeleteMultiplePagesMutation
	| DuplicatePageMutation
	| ReorderSpreadPagesMutation
	| ProductMutations
	| LogoMutations
	| LayoutMutations
	| SettingsMutations
	| UpdateProductSettingsMutation
	| CoverMutations
	| UpdatePageMediaMutation

type AddProductMutation = EventPayload<
	`${PageNamespace}/addProduct`,
	{ product: Product; page: NormalizedPage }
>
type RemoveProductMutation = EventPayload<
	`${PageNamespace}/removeProduct`,
	{ pageId: string; slotId: number; page: NormalizedPage }
>

type ProductMutations = AddProductMutation | RemoveProductMutation

type AddLogoMutation = EventPayload<
	`${PageNamespace}/addLogo`,
	{ logo: Logo; page: NormalizedPage }
>
type RemoveLogoMutation = EventPayload<
	`${PageNamespace}/removeLogo`,
	{ pageId: string; slotId: number; page: NormalizedPage }
>
type ReplacePageLogo = EventPayload<
	`${PageNamespace}/replacePageLogo`,
	{ logo: Logo; page: Page }
>
type ReplaceAllPagesLogo = EventPayload<
	`${PageNamespace}/replaceAllPagesLogo`,
	{ logo: Logo; pages: Page[] }
>
type LogoMutations =
	| AddLogoMutation
	| RemoveLogoMutation
	| ReplacePageLogo
	| ReplaceAllPagesLogo

type ChangeLayoutMutation = EventPayload<
	`${PageNamespace}/changeLayout`,
	{ page: NormalizedPage; layout: Layout }
>

type LayoutMutations = ChangeLayoutMutation

// TO-DO Clean-up and remove template settings, then use new schema
type SetPageSettingsMutation = EventPayload<
	`${PageNamespace}/setPageSettings`,
	{
		page: NormalizedPage
		settings: NormalizedPage['settings']
		options?: MutationExtraOptions
	}
>
type UpdateProductSettingsMutation = EventPayload<
	`${PageNamespace}/updateProductSettings`,
	{
		page: NormalizedPage
		settings: NormalizedPage['settings']['products']
	}
>

export type SettingsMutations = SetPageSettingsMutation

type AddCoverImageMutation = EventPayload<
	`${PageNamespace}/addCoverImage`,
	{ page: NormalizedPage; coverImage: string }
>

type RemoveCoverImageMutation = EventPayload<
	`${PageNamespace}/removeCoverImage`,
	{ page: NormalizedPage }
>

type SetTitleMutation = EventPayload<
	`${PageNamespace}/setTitle`,
	{ page: NormalizedPage; title: string }
>

type UpdatePageMediaMutation = EventPayload<
	`${PageNamespace}/updatePageMedia`,
	Pick<NormalizedPage, 'pageId'>
>

export type CoverMutations =
	| AddCoverImageMutation
	| RemoveCoverImageMutation
	| SetTitleMutation

function pagesToMap<T extends Page | BasicPage>(pages: T[]) {
	if (!pages.length) return {}
	return pages.reduce<Record<string, T>>((pages: Record<string, T>, page: T) => {
		return {
			...pages,
			[page.index]: { ...page },
		}
	}, {})
}

function reindexPages<T extends Page | BasicPage>(pages: T[]) {
	if (!pages.length) return []
	return pages.map((page, index) => ({ ...page, index: index + 1 }))
}

function formatPageToBasicPage(page: Page) {
	return {
		index: page.index,
		page_id: page.page_id,
		spread_page: page.settings?.feature?.spreadPage ?? null,
	}
}

export const pageStore: Module<PageStore, RootStore> = {
	namespaced: true,
	state: () => ({
		pages: {},
		basicPages: {},
	}),
	mutations: {
		setPages(state, { payload }: SetPagesMutation) {
			state.pages = pagesToMap(payload)
		},
		setBasicPages(state, { payload }: SetBasicPagesMutation) {
			state.basicPages = pagesToMap(payload)
		},
		addPages(state, { payload }: AddPagesMutation) {
			state.pages = { ...state.pages, ...pagesToMap(payload) }
			state.basicPages = {
				...state.basicPages,
				...pagesToMap(payload.map(formatPageToBasicPage)),
			}
		},
		updatePagesIndexes(state, { payload }: UpdatePagesIndexesMutation) {
			const sortedPages = pagesToMap(payload)
			state.pages = sortedPages
			state.basicPages = {
				...state.basicPages,
				...pagesToMap(payload.map(formatPageToBasicPage)),
			}
		},
		addPage(state, { payload }: AddPageMutation) {
			state.pages[payload.index] = payload
			state.basicPages[payload.index] = formatPageToBasicPage(payload)
		},
		deletePage(state, { payload }: DeletePageMutation) {
			const filteredPages = Object.values(state.pages).filter(
				(page) => page?.page_id !== payload.page_id
			)
			const filteredBasicPages = Object.values(state.basicPages).filter(
				(page) => page?.page_id !== payload.page_id
			)
			state.pages = pagesToMap(reindexPages(filteredPages))
			state.basicPages = pagesToMap(reindexPages(filteredBasicPages))
		},
		deleteMultiplePages(state, { payload }: DeleteMultiplePagesMutation) {
			const filteredPages = Object.values(state.pages).filter(
				(page) =>
					!payload.some((payloadPage) => payloadPage.page_id === page.page_id)
			)
			const filteredBasicPages = Object.values(state.basicPages).filter(
				(page) =>
					!payload.some((payloadPage) => payloadPage.page_id === page.page_id)
			)

			state.pages = pagesToMap(reindexPages(filteredPages))
			state.basicPages = pagesToMap(reindexPages(filteredBasicPages))
		},
		reorderSpreadPages(state, { payload }: ReorderSpreadPagesMutation) {
			const { leftPage, rightPage } = payload

			state.pages = {
				...state.pages,
				[leftPage.index]: { ...leftPage },
				[rightPage.index]: { ...rightPage },
			}
			state.basicPages = {
				...state.basicPages,
				[leftPage.index]: { ...formatPageToBasicPage(leftPage) },
				[rightPage.index]: { ...formatPageToBasicPage(rightPage) },
			}
		},
		duplicatePage(state, { payload }: DuplicatePageMutation) {
			const pages = Object.values(state.pages)
			const basicPages = Object.values(state.basicPages)
			const lastCurrentPageIndex = payload.pages.length - 1
			pages.splice(
				payload.pages[lastCurrentPageIndex].index - payload.pages.length,
				0,
				...payload.pages
			)
			basicPages.splice(
				payload.pages[lastCurrentPageIndex].index - payload.pages.length,
				0,
				...payload.pages.map(formatPageToBasicPage)
			)

			state.pages = pagesToMap(reindexPages(pages))
			state.basicPages = {
				...state.basicPages,
				...pagesToMap(reindexPages(basicPages)),
			}
		},
		addProduct(state, { payload }: AddProductMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return

			page.products = {
				...page.products,
				[payload.product.slot]: { ...payload.product },
			}
		},
		removeProduct(state, { payload }: RemoveProductMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return

			const { [payload.slotId]: _toBeDeleted, ...restProducts } = page.products
			page.products = restProducts
		},
		addLogo(state, { payload }: AddLogoMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return

			page.logos = {
				...page.logos,
				[payload.logo.slot]: {
					...payload.logo,
				},
			}
		},
		removeLogo(state, { payload }: RemoveLogoMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return

			const logoData = page.logos[payload.slotId]
			page.logos = {
				...page.logos,
				[payload.slotId]: {
					...logoData,
					logo_id: '',
					url: '',
				},
			}
		},
		replacePageLogo(state, { payload }: ReplacePageLogo) {
			const page = state.pages[payload.page.index]
			if (!page) return

			page.logos = {
				...page.logos,
				[payload.logo.slot]: { ...payload.logo },
			}
		},
		replaceAllPagesLogo(state, { payload }: ReplaceAllPagesLogo) {
			const { pages } = payload

			pages.forEach((page) => {
				const { isAutoLogo } = page.settings?.feature ?? {}
				if (!page || !isAutoLogo) return

				page.logos = {
					...page.logos,
					[payload.logo.slot]: { ...payload.logo },
				}
			})
		},
		changeLayout(state, { payload }: ChangeLayoutMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return

			const { layout } = payload

			page.layout = {
				layout_id: layout.layout_id,
				logos_slots_quantity: layout.logos_slots_quantity,
				name: layout.name,
				products_slots_quantity: layout.products_slots_quantity,
			}
			page.layout_id = layout.layout_id
			page.title = ''
		},
		setPageSettings(state, { payload }: SetPageSettingsMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return
			page.settings = { ...page.settings, ...payload.settings }
		},
		updateProductSettings(state, { payload }: UpdateProductSettingsMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return
			page.settings = {
				...page.settings,
				products: {
					...page.settings?.products,
					...payload.settings,
				},
			}
		},
		addCoverImage(state, { payload }: AddCoverImageMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return
			page.cover_image = payload.coverImage
		},
		removeCoverImage(state, { payload }: RemoveCoverImageMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return
			page.cover_image = ''
		},
		setTitle(state, { payload }: SetTitleMutation) {
			const page = state.pages[payload.page.index]
			if (!page) return
			page.title = payload.title
		},
		updatePageMedia() {
			// Triggers media generation via WS (thumbnails)
			return
		},
	},
}
