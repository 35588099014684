import { reactive, computed } from 'vue'
import {
	AssetVariation,
	CreateVirtualSamplesBatchResponse,
	FetchAssetImprintAreasResponse,
	FetchAssetsResponse,
	FetchAssetsSettingsResponse,
	VirtualSamplesByTaskIdFetchResponse,
	VirtualSamplesCreateResponse,
	VirtualSamplesEditResponse,
	VirtualSamplesFetchResponse,
	VirtualSampleDetails,
	useCreateAssetsServiceInstance,
	useCreateUnsplashInstance,
	useMakeVirtualsServiceInstance,
	LibraryAsset,
} from '@zoomcatalog/design-system'
import {
	FetchProductAttributesResponse,
	FetchProductByIdResponse,
	FetchProductsResponse,
	RawProductVariation,
	useCreateProductLibraryServiceInstance,
} from '@zoomcatalog/design-templates'
import { useDesignSettingsStore } from '@/composable/StoreUtils/useDesignSettingsStore'
import api from '@/config/axios'
import {
	GET_ASSET_LIBRARY_SEARCHABLE,
	GET_ASSET_LIBRARY,
	PRODUCTS_GET_BY_KEYWORD,
	PRODUCT_GET_BY_ID,
	GET_PRODUCT_ATTRIBUTES_LIST,
} from '@/config/endpoints'
import unsplashApi from '@/config/unsplash'
import { useSelector } from '@/store/rootStore'

export function useSetupServices() {
	const endpoints = useSelector((state) => state.sniffer.endpoints)
	const companyId = useSelector((state) => state.auth.company?.company_id ?? '')

	const { settings } = useDesignSettingsStore()

	const plInstance = useCreateProductLibraryServiceInstance(
		reactive({
			currency: computed(() => settings.value.currency) as unknown as string,
			async getProductsQuery({ query, currency, limit, offset, filters }) {
				const { endpoint } = PRODUCTS_GET_BY_KEYWORD

				return api
					.get<FetchProductsResponse>(endpoint, {
						params: { q: query, currency, limit, offset, filters },
					})
					.then((data) => data.data)
			},
			async getProductByIdQuery({ productId, partId, filters }) {
				const { endpoint } = PRODUCT_GET_BY_ID

				return api
					.get<{ contents: FetchProductByIdResponse }>(endpoint(productId), {
						params: {
							cy_part_id: partId,
							...(filters?.length ? { filters: `[${filters?.join(',')}]` } : {}),
						},
					})
					.then((data) => data.data.contents)
			},
			async getProductByPartIdQuery({ productId, partId, filters }) {
				const { endpoint } = PRODUCT_GET_BY_ID

				return api
					.get<{ contents: RawProductVariation }>(endpoint(productId), {
						params: {
							part_id: partId,
							...(filters?.length ? { filters: `[${filters?.join(',')}]` } : {}),
						},
					})
					.then((data) => data.data.contents)
			},
			async getProductAttributesQuery({ productAttribute, relatedTo }) {
				return api
					.get<FetchProductAttributesResponse>(
						GET_PRODUCT_ATTRIBUTES_LIST.endpoint(productAttribute ?? ''),
						{
							...(relatedTo ? { params: { related_to: relatedTo } } : {}),
						}
					)
					.then((data) => data.data)
			},
		})
	)

	const assetsInstance = useCreateAssetsServiceInstance({
		async getAssetById(assetId, params) {
			return api
				.get<LibraryAsset>(`/asset/${assetId}`, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					params,
				})
				.then((data) => data.data)
		},
		async getPreSignedUrl(data) {
			let assets = [
				{
					is_for_company: false,
					filename: data.filename,
					is_public: 0,
					limited_to: 'owner',
				},
			]

			if (Array.isArray(data.filename)) {
				assets = data.filename.map((name) => ({
					is_for_company: false,
					filename: name,
					is_public: 0,
					limited_to: 'owner',
				}))
			}

			return api
				.post<{
					res: Array<{
						fields: {
							AWSAccessKeyId: string
							acl: string
							'content-type': string
							key: string
							policy: string
							signature: string
							success_action_status: string
						}
						url: string
						uuid: string
					}>
				}>(
					'/asset/upload',
					{
						assets,
						tags: data.tags,
						is_public: 0,
						limited_to: 'owner',
					},
					{
						baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
						headers: {
							'X-Zoom-Company': companyId.value,
						},
					}
				)
				.then((response) => response.data)
		},
		getResizeUrl(
			assetId,
			[w, h],
			urlOptions?: { repositionResize?: boolean; fit?: string }
		) {
			return `${
				endpoints.value.ASSET_SERVICE_API_ENDPOINT
			}/asset/${assetId}/resize/${w}x${h ?? ''}${
				urlOptions?.fit ? '?fit=' + urlOptions.fit : ''
			}`
		},
		async getAssetsQuery(data) {
			const { endpoint } = GET_ASSET_LIBRARY

			return api
				.get<FetchAssetsResponse>(endpoint(data.tags), {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					headers: {
						'Cache-Control': 'max-age=0',
					},
					params: {
						limit: data.limit,
						sort: '-created',
						offset: data.offset,
						q: data.search,
						is_active: data.isActive,
						filters: decodeURIComponent(data.filters || ''),
					},
				})
				.then((data) => data.data)
		},
		async getAssetsQuerySearchable(data) {
			return api
				.get<FetchAssetsResponse>(GET_ASSET_LIBRARY_SEARCHABLE.endpoint, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					headers: {
						'Cache-Control': 'max-age=0',
						'X-Zoom-Company': companyId.value,
					},
					params: {
						...(data.tags.some(Boolean) ? { tags: data.tags.join('+') } : {}),
						...(data.fields?.some(Boolean) ? { fields: data.fields.join(',') } : {}),
						limit: data.limit,
						offset: data.offset,
						q: data.search,
						filters: decodeURIComponent(data.filters || ''),
						sort: data.sort,
					},
				})
				.then((data) => data.data)
		},
		async uploadSingleAsset(assetData, formData) {
			return api
				.post(assetData.url, formData, {
					baseURL: undefined,
					headers: {
						'X-Zoom-Company': companyId.value,
					},
					transformRequest: (data, headers) => {
						if (headers) {
							// TODO: Check why headers.common is set to string instead of object
							delete (headers.common as unknown as Record<string, string>)
								.Authorization
						}
						return data
					},
				})
				.then(() => ({ data: assetData }))
		},
		async updateAsset(assetId, data) {
			await api.patch(
				`${endpoints.value.ASSET_SERVICE_API_ENDPOINT}/asset/${assetId}`,
				data
			)
		},
		getOriginalUrl(assetId) {
			return `${endpoints.value.ASSET_SERVICE_API_ENDPOINT}/asset/${assetId}/original`
		},
		getUnwrappedS3Url(url: string) {
			return api
				.head<string>(url, {
					baseURL: undefined,
					transformRequest: (data, headers) => {
						if (headers) {
							delete (headers.common as unknown as Record<string, string>)
								.Authorization
						}
						return data
					},
				})
				.then((response) => {
					const finalURL = new URL(response.request.responseURL)
					return finalURL.href
				})
		},
		async getAssetImprintAreasQuery(assetId: string) {
			return api
				.get<FetchAssetImprintAreasResponse>(
					`/asset/${assetId}/imprint-areas?filters=is_active=true&sort=${[
						'-is_default',
						'is_verified',
						'name',
					].join(',')}`,
					{
						baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					}
				)
				.then((data) => data.data)
		},
		async getAssetSettingsQuery(assetId, data) {
			return api
				.get<FetchAssetsSettingsResponse>(`/asset/${assetId}/settings`, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					headers: {
						'Cache-Control': 'max-age=0',
					},

					params: data,
				})
				.then((data) => data.data)
		},
		async getAssetVariations(assetId) {
			return api
				.get<AssetVariation[]>(`/asset/${assetId}/variations`, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					headers: {
						'Cache-Control': 'max-age=0',
					},
				})
				.then((data) => data.data)
		},
		async createAssetVariation(assetId, payload) {
			return api
				.post<{ variation_id: string }>(`/asset/${assetId}/variation`, payload, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
				})
				.then((data) => data.data)
		},
		async getAssetVariationById(assetId, variationId) {
			return api
				.get<AssetVariation>(`/asset/${assetId}/variation/${variationId}`, {
					baseURL: endpoints.value.ASSET_SERVICE_API_ENDPOINT,
					headers: {
						'Cache-Control': 'max-age=0',
					},
				})
				.then((data) => data.data)
		},
		getTransformUrl({ assetId, size: [w, h], removeBackground, changeColor }) {
			const baseUrl = endpoints.value.ASSET_SERVICE_API_ENDPOINT

			const getChangeColorOnly = (color: string) => {
				return `${baseUrl}/asset/${assetId}/color/${color}`
			}
			const getRemoveBackgroundOnly = () => {
				return `${baseUrl}/asset/${assetId}/transparent`
			}

			const getRemoveContinuousOnly = () => {
				return `${baseUrl}/asset/${assetId}/nowhite/continuous`
			}

			const getChangeColorRemoveBg = (color: string) => {
				return `${baseUrl}/asset/${assetId}/transparent/color/${color}`
			}

			const getChangeColorRemoveContinuous = (color: string) => {
				return `${baseUrl}/asset/${assetId}/nowhite/continuous/color/${color}`
			}

			const getResizeImage = () => {
				return `${baseUrl}/asset/${assetId}/resize/${w}x${h ?? ''}`
			}

			function formImageUrl() {
				const image = changeColor
					? removeBackground
						? removeBackground === 'all-background'
							? getChangeColorRemoveBg(changeColor)
							: getChangeColorRemoveContinuous(changeColor)
						: getChangeColorOnly(changeColor)
					: removeBackground
					? removeBackground === 'all-background'
						? getRemoveBackgroundOnly()
						: getRemoveContinuousOnly()
					: getResizeImage()

				return image
			}

			return formImageUrl()
		},
	})

	const unsplashInstance = useCreateUnsplashInstance({
		async searchPhotos(data) {
			return unsplashApi.searchPhotos({
				query: data.query,
				page: data.page,
				perPage: data.perPage,
			})
		},

		async trackDownload(data) {
			return unsplashApi.trackDownload(data)
		},
	})

	const virtualsService = useMakeVirtualsServiceInstance({
		async getVirtual(id, ref) {
			return api
				.get<VirtualSampleDetails>(`/virtual/${id}?ref=${ref}`, {
					baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
				})
				.then((data) => data.data)
		},
		async getAccountVirtuals(options) {
			return api
				.get<VirtualSamplesFetchResponse>('/account/virtuals/published', {
					baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
					params: {
						fields: ['images', 'layers_image_ref', 'product_image_ref'].join(','),
						...options,
					},
				})
				.then((data) => data.data)
		},
		async createAccountVirtual(payload) {
			return api
				.post<VirtualSamplesCreateResponse>('/virtual/publish', payload, {
					baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
				})
				.then((data) => data.data)
		},
		async editAccountVirtual(payload) {
			return api
				.patch<VirtualSamplesEditResponse>('/virtual/publish', payload, {
					baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
				})
				.then((data) => data.data)
		},
		async createAccountVirtualsBatch(payload) {
			return api
				.post<CreateVirtualSamplesBatchResponse>('/virtuals/publish', payload, {
					baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
				})
				.then((data) => data.data)
		},
		async getVirtualsByTaskId(options) {
			return api
				.get<VirtualSamplesByTaskIdFetchResponse>(
					`/virtuals/publish/${options.taskId}`,
					{
						params: {
							details: 'full',
						},
						baseURL: endpoints.value.VIRTUAL_SAMPLES_API_ENDPOINT,
					}
				)
				.then((data) => data.data)
		},
	})

	return {
		plInstance,
		virtualsService,
		assetsInstance,
		unsplashInstance,
	}
}
